import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "sebankid",
      "nameidentifier": "1373c272b61a4cb588b29c44883fe62f",
      "sub": "{1373c272-b61a-4cb5-88b2-9c44883fe62f}",
      "ssn": "196802020575",
      "name": "Terne Paulsen",
      "given_name": "Terne",
      "family_name": "Paulsen",
      "ipaddress": "77.241.128.160",
      "country": "SE"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      