import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Almost all eID types have a notion of test users and real users.`}</p>
    <p><em parentName="p">{`Real users`}</em>{` are real people logging in to a web site, thus voluntering their real name and typically also a social security number, SSN.`}</p>
    <p><em parentName="p">{`Test users`}</em>{` are either created by you for the occasion, or we provide you with access to already created test users. `}</p>
    <p>{`You may read more in the `}<a parentName="p" {...{
        "href": "/verify/e-ids"
      }}>{`section on eIDs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      