import Layout from "../../../../src/layouts/mdx";
import CodeFlowSnippet from "../../../../src/snippets/oauth2-code-flow.mdx";
import LoginMethodsSnippet from "../../../../src/snippets/login-methods-and-path-encoded.mdx";
import TestUsersSnippet from "../../../../src/snippets/test-users.mdx";
import ProductionSnippet from "../../../../src/snippets/set-up-production.mdx";
import * as React from 'react';
export default {
  Layout,
  CodeFlowSnippet,
  LoginMethodsSnippet,
  TestUsersSnippet,
  ProductionSnippet,
  React
};