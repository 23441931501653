import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Login method`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`acr_values`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`base64 encoded`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Norwegian BankID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Mobile or Web (user choice):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:no:bankid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpubzpiYW5raWQ=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`BankID Biometrics (level substantial):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:no:bankid:substantial`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpubzpiYW5raWQ6c3Vic3RhbnRpYWw=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Norwegian Vipps Login`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Login with Vipps app:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:no:vipps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpubzp2aXBwcw==`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Swedish BankID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`All options (user chooses):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpzZTpiYW5raWQ=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Same device:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid:same-device`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpzZTpiYW5raWQ6c2FtZS1kZXZpY2U=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Another device (aka mobile):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid:another-device`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpzZTpiYW5raWQ6YW5vdGhlci1kZXZpY2U=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`QR code:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid:another-device:qr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpzZTpiYW5raWQ6YW5vdGhlci1kZXZpY2U6cXI=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Danish MitID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Level low:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:dk:mitid:low`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpkazptaXRpZDpsb3c=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Level substantial:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:dk:mitid:substantial`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpkazptaXRpZDpzdWJzdGFudGlhbA==`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`MitID Erhverv (MitID Business):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:dk:mitid:business`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpkazptaXRpZDpidXNpbmVzcw==`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Finnish Trust Network`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`BankID:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:fi:bankid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpmaTpiYW5raWQ=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Mobile certificate (Mobiilivarmenne):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:fi:mobile-id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpmaTptb2JpbGUtaWQ=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Both of the above:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:fi:all`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpmaTphbGw=`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Itsme`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Basic:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:itsme:basic`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjppdHNtZTpiYXNpYw==`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Advanced:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:itsme:advanced`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjppdHNtZTphZHZhbmNlZA==`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Belgium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Verified e-ID:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:be:eid:verified`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dXJuOmdybjphdXRobjpiZTplaWQ6dmVyaWZpZWQ=`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      