import Layout from "../../../../src/layouts/mdx";
import SwedishBankIdJwtSnippet from "../../../../src/snippets/jwt-payloads/swedish-bankid";
import NorwegianBankIdJwtSnippet from "../../../../src/snippets/jwt-payloads/norwegian-bankid";
import NorwegianBankIdBiometricJwtSnippet from "../../../../src/snippets/jwt-payloads/norwegian-bankid-biometric";
import NorwegianVippsJwtSnippet from "../../../../src/snippets/jwt-payloads/norwegian-vipps";
import DanishMitIDJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid";
import DanishMitIDAddressJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid-address";
import DanishMitIDCompanySignatoryJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid-erhverv-company-signatory";
import DanishMitIDErhvervJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid-erhverv";
import FtnJwtSnippet from "../../../../src/snippets/jwt-payloads/finnish-trust-network";
import BelgianEidJwtSnippet from "../../../../src/snippets/jwt-payloads/belgian-eid";
import BelgianItsmeJwtSnippet from "../../../../src/snippets/jwt-payloads/belgian-itsme";
import * as React from 'react';
export default {
  Layout,
  SwedishBankIdJwtSnippet,
  NorwegianBankIdJwtSnippet,
  NorwegianBankIdBiometricJwtSnippet,
  NorwegianVippsJwtSnippet,
  DanishMitIDJwtSnippet,
  DanishMitIDAddressJwtSnippet,
  DanishMitIDCompanySignatoryJwtSnippet,
  DanishMitIDErhvervJwtSnippet,
  FtnJwtSnippet,
  BelgianEidJwtSnippet,
  BelgianItsmeJwtSnippet,
  React
};