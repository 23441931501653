import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "novippslogin",
      "nameidentifier": "75dca5991ad74a0981c092d424b82fbc",
      "sub": "{75dca599-1ad7-4a09-81c0-92d424b82fbc}",
      "address": {
        "street_address": "BOKS 6300, ETTERSTAD",
        "postal_code": "0603",
        "region": "OSLO",
        "country": "NO",
        "formatted": "BOKS 6300, ETTERSTAD\n0603\nOSLO\nNO"
      },
      "birthdate": "1955-09-82",
      "emailaddress": "mikkel@criipto.com",
      "mobilephone": "4748059940",
      "socialno": "10098235846",
      "family_name": "Ggacbs",
      "given_name": "Mlihgw",
      "name": "Mlihgw Ggacbs",
      "country": "NO"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      