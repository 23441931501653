import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "fitupas",
      "nameidentifier": "788eb5abb2f84c0994a4d359f416f7ca",
      "sub": "{788eb5ab-b2f8-4c09-94a4-d359f416f7ca}",
      "name": "_19cfbd642c4a82b08613b841caf0e153c5956c14",
      "country": "FI",
      "given_name": "Väinö",
      "family_name": "Tunnistus",
      "birthdate": "1970-07-07",
      "satu": "",
      "hetu": "070770-905D"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      