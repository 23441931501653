import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "dkmitid",
      "nameidentifier": "0f9960a0d28d4353a3e2ea07f8ffa185",
      "sub": "{0f9960a0-d28d-4353-a3e2-ea07f8ffa185}",
      "uuid": "74ffcd31-fbaf-4c33-bdac-169f25c1e416",
      "cprNumberIdentifier": "2101270087",
      "birthdate": "1927-01-21",
      "age": "93",
      "name": "Ditlev Von Testesen",
      "country": "DK"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      