import Layout from "../../../../src/layouts/mdx";
import GraphQLExample from "../../../../src/components/GraphQLExample";
import basicCreateSignatureOrderExample from "../../../../src/examples/createSignatureOrder/basic";
import removePreviousSignaturesExample from "../../../../src/examples/createSignatureOrder/removePreviousSignatures";
import criiptoVerifyOptionsExample from "../../../../src/examples/createSignatureOrder/criiptoVerifyOptions";
import evidenceValidationStagesExample from "../../../../src/examples/createSignatureOrder/evidenceValidationStages";
import uiExample from "../../../../src/examples/createSignatureOrder/ui";
import uniqueEvidenceKeyExample from "../../../../src/examples/createSignatureOrder/uniqueEvidenceKey";
import displayDocumentIDExample from "../../../../src/examples/createSignatureOrder/displayDocumentID";
import addSignatoryBasicExample from "../../../../src/examples/addSignatory/basic";
import addSignatoryEvidenceValidationExample from "../../../../src/examples/addSignatory/evidenceValidation";
import addSignatoryRoleExample from "../../../../src/examples/addSignatory/role";
import addSignatoryDocumentsExample from "../../../../src/examples/addSignatory/documents";
import addSignatoryPreapprovedExample from "../../../../src/examples/addSignatory/preapproved";
import addSignatorySealPositionExample from "../../../../src/examples/addSignatory/sealPosition";
import addSignatoriesExample from "../../../../src/examples/addSignatories/basic";
import createSignatureAppearanceExample from "../../../../src/examples/createSignatureOrder/signatureAppearance";
import addSignatoryHeaderLeftExample from "../../../../src/examples/addSignatory/headerLeft";
import addSignatoryDisplayNameExample from "../../../../src/examples/addSignatory/displayName";
import addSignatoryFooterExample from "../../../../src/examples/addSignatory/footer";
import basicCloseSignatureOrderExample from "../../../../src/examples/closeSignatureOrder/basic";
import retentionCloseSignatureOrderExample from "../../../../src/examples/closeSignatureOrder/retention";
import * as closeSignatureOrderExample from "../../../../src/examples/closeSignatureOrder.graphql";
import cancelSignatureOrderExample from "../../../../src/examples/cancelSignatureOrder/basic";
import createDrawableExample from "../../../../src/examples/createSignatureOrder/drawable";
import addSignatoryDrawableExample from "../../../../src/examples/addSignatory/drawable";
import changeSignatoryDrawableExample from "../../../../src/examples/changeSignatory/drawable";
import * as signActingAsExample from "../../../../src/examples/signActingAs.graphql";
import compositeAllOfExample from "../../../../src/examples/createSignatureOrder/composite-allOf";
import validateDocumentExample from "../../../../src/examples/validateDocument/basic";
import * as React from 'react';
export default {
  Layout,
  GraphQLExample,
  basicCreateSignatureOrderExample,
  removePreviousSignaturesExample,
  criiptoVerifyOptionsExample,
  evidenceValidationStagesExample,
  uiExample,
  uniqueEvidenceKeyExample,
  displayDocumentIDExample,
  addSignatoryBasicExample,
  addSignatoryEvidenceValidationExample,
  addSignatoryRoleExample,
  addSignatoryDocumentsExample,
  addSignatoryPreapprovedExample,
  addSignatorySealPositionExample,
  addSignatoriesExample,
  createSignatureAppearanceExample,
  addSignatoryHeaderLeftExample,
  addSignatoryDisplayNameExample,
  addSignatoryFooterExample,
  basicCloseSignatureOrderExample,
  retentionCloseSignatureOrderExample,
  closeSignatureOrderExample,
  cancelSignatureOrderExample,
  createDrawableExample,
  addSignatoryDrawableExample,
  changeSignatoryDrawableExample,
  signActingAsExample,
  compositeAllOfExample,
  validateDocumentExample,
  React
};