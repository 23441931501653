import Layout from "../../../../src/layouts/mdx";
import { ImageContainer } from "../../../../src/components/MdxProvider";
import ConsentRevocation from "../../../../src/components/ConsentRevocation";
import DanishMitIDJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid";
import DanishMitIDAddressJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid-address";
import * as React from 'react';
export default {
  Layout,
  ImageContainer,
  ConsentRevocation,
  DanishMitIDJwtSnippet,
  DanishMitIDAddressJwtSnippet,
  React
};