import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "nobankid-oidc",
      "authenticationtype": "urn:grn:authn:no:bankid:substantial",
      "nameidentifier": "cde37629c67b4318988ca0b378931e7d",
      "sub": "{cde37629-c67b-4318-988c-a0b378931e7d}",
      "uniqueuserid": "9578-6000-4-476957",
      "certissuer": "CN=BankID - TestBank1 - Bank CA 3,OU=123456789,O=TestBank1 AS,C=NO;OrginatorId=9980;OriginatorName=BINAS;OriginatorId=9980",
      "birthdate": "1941-08-16",
      "dateofbirth": "1941-08-16",
      "emailaddress": "mikkel@criipto.com",
      "email": "mikkel@criipto.com",
      "mobilephone": "90724328",
      "phone_number": "90724328",
      "socialno": "16084138758",
      "family_name": "CriiptoTestesen",
      "surname": "CriiptoTestesen",
      "given_name": "Mikkel",
      "givenname": "Mikkel",
      "name": "Mikkel CriiptoTestesen",
      "country": "NO"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      