import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Criipto Verify supports a range of country and bank specific eID services. They are all accessed through the same endpoints, e.g. `}<inlineCode parentName="p">{`https://<YOUR COMPANY>.criipto.id/oauth2/authorize`}</inlineCode></p>
    <p>{`To pick the login method you must set the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` parameter on the authentication request in order to choose the type of authentication you want. How you set this query string parameter varies with programming platform and your OpenID Connect library of choice.`}</p>
    <p>{`The current list of possible values is:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Login method`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`acr_values`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Norwegian BankID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Mobile or Web (user choice):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:no:bankid`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Norwegian Vipps Login`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Login with Vipps app:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:no:vipps`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Swedish BankID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`All options (user chooses):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Same device:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid:same-device`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Another device (aka mobile):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid:another-device`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`QR code:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:se:bankid:another-device:qr`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Danish MitID`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Level low:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:dk:mitid:low`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Level substantial:`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:dk:mitid:substantial`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`MitID Erhverv (MitID Business):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:dk:mitid:business`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Finnish Trust Network`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`BankID:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:fi:bank-id`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Mobile certificate (Mobiilivarmenne):`}{` `}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:fi:mobile-id`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Both of the above:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:fi:all`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Itsme`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Basic:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:itsme:basic`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Advanced:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:itsme:advanced`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Belgium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{` `}{` `}{`Verified e-ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`urn:grn:authn:be:eid:verified`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      