import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you are registering a new application, you must first save the configuration.`}</p>
    <p>{`Once you have a saved application registration you may configure the OAuth2 code flow. `}</p>
    <p>{`Open the application registration and configure it for the right OAuth2 flow:`}</p>
    <ol>
      <li parentName="ol">{`Enable OAuth2 code flow`}</li>
      <li parentName="ol">{`Copy the generated client secret. `}</li>
      <li parentName="ol">{`Set the user info response strategy to `}<inlineCode parentName="li">{`plainJson`}</inlineCode>{` to enable retrieval of plain JSON user information from the `}<inlineCode parentName="li">{`/oauth2/userinfo`}</inlineCode>{` endpoint.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.39583333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABrElEQVQ4y62Su4oUQRSG6/l8ATMTxcjAxMhAMTEQRTNBNFgdE3EfYGFXELyAiBgswioGOtrdVd1dt+memerp/qRqGHaQXXcGLPj4qTqninPOX+JXVvD1+5ha11R1RZZLyqpiNvWJ+WzCbDpJehoxvsoXrz9+4fDbz7RRpaLWFVrXlFWJKsu1R/1GCOs0WSEplMI5w7T1tI1L+q+q/qYLTVIBHcvVAwvW1zAMDEN/Kn3fp7xcWc5deci7z0eIt5+OuP14j/vPDrj3dJ8Ho1fc3Tng0e4b2rZh0c0IYUp3AvE8xr337L8/JJcK8XLvA+evPeHyredcvDHi0s0RF67vcPXOC/zEEOZnzy/mQGARGgTDPLXad00KDIs2BSJxputOn8TKsDj3ZEo8jAZYq9PXWakxOj24jTHJFGNqsiJHKkkuJVIpsrygrpd/sZnYjYmFCa0rfowzslxhjcFak6o7xpyBTnekKrFGI9rW0zRxBkuNhHlLF1rCFsT845bzPLVZSJlwVuO9wbsN8SbdicaIaML4d4FUFd47nHNYa7fCOUtZLQ0Vscwwb/4LseU/ge4nVL6sIEwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OAuth2 code flow",
          "title": "OAuth2 code flow",
          "src": "/static/226bc8b6e5fe92ec204ef2bf10d69e29/c5bb3/oauth2-code-flow.png",
          "srcSet": ["/static/226bc8b6e5fe92ec204ef2bf10d69e29/8514f/oauth2-code-flow.png 192w", "/static/226bc8b6e5fe92ec204ef2bf10d69e29/804b2/oauth2-code-flow.png 384w", "/static/226bc8b6e5fe92ec204ef2bf10d69e29/c5bb3/oauth2-code-flow.png 680w"],
          "sizes": "(max-width: 680px) 100vw, 680px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><em parentName="p">{`Note that this is the only time you will be shown the actual value of the client secret`}</em>{`. Criipto only stores this as a hashed value, which means you cannot retrieve the value once it has been generated and stored.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "420px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.541666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9ElEQVQoz5VR2U4DMQzM//8eDyBataUUKIXNUW2SzTnIzmZZFYSEpZHH4yO2IlLKiCkj5wLiHTFmJNJWOtWVUkFWa11ARnnnA8Rm/47N4YLTWWF7uOD4KrF//sTj7ozjy8Da3cMJu6cP3G/foIxtA/G7iZQScs4M4mn2PY4pIYQ4b/lde4u+qdBa43o1MMZAK4VhGCClZE6ecuSVUgyqv+XU45xrA9Xc2BIS3nsGFXS+1pz3mKbpR85ay1sKCsZxXMS/ziKUUhqY5+ZnnQdaO2K9JZ+uNcd8utbLaZ2HEHjY+qEYIy/Dn0LBf7B8WibfQFqtBV9xf23ASmpPSQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OAuth2 client secret",
          "title": "OAuth2 client secret",
          "src": "/static/472b94bfc31a23911e7e69b957d68fde/d10fb/oauth2-client-secret.png",
          "srcSet": ["/static/472b94bfc31a23911e7e69b957d68fde/8514f/oauth2-client-secret.png 192w", "/static/472b94bfc31a23911e7e69b957d68fde/804b2/oauth2-client-secret.png 384w", "/static/472b94bfc31a23911e7e69b957d68fde/d10fb/oauth2-client-secret.png 420w"],
          "sizes": "(max-width: 420px) 100vw, 420px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`Some libraries do not support the final `}<inlineCode parentName="p">{`userinfo`}</inlineCode>{` request. In those cases you will need to fetch the user data directly from the `}<inlineCode parentName="p">{`token`}</inlineCode>{` endpoint as opposed to the `}<inlineCode parentName="p">{`userinfo`}</inlineCode>{` endpoint. Do this by choosing the appropriate option as shown below.`}</p>
    </Highlight>
    <p>{`You may configure Criipto Verify  to retrieve the user information from  either the `}<inlineCode parentName="p">{`userinfo`}</inlineCode>{` endpoint - the default option - or you may explicitly choose the `}<inlineCode parentName="p">{`fromTokenEndpoint`}</inlineCode>{` in the user info response strategy instead:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "15.625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAYklEQVQI143OXQqAMAwD4N3/kKKigrNd/YvrniLOC+zhIyUQaDgOY0rKZEpRqbnGyK8vftFzu+I3wzfuhvnXT+zHhXHbqKo0S012M4pIfSg8uAjcRM3/Lv5UntHGwZxB4OQLnIXlmJZV6IYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "User info response strategy",
          "title": "User info response strategy",
          "src": "/static/46b8fc1c85268965e0ec411642c9c95d/c5bb3/userinfo-responsestrategy-fromtokenendpoint.png",
          "srcSet": ["/static/46b8fc1c85268965e0ec411642c9c95d/8514f/userinfo-responsestrategy-fromtokenendpoint.png 192w", "/static/46b8fc1c85268965e0ec411642c9c95d/804b2/userinfo-responsestrategy-fromtokenendpoint.png 384w", "/static/46b8fc1c85268965e0ec411642c9c95d/c5bb3/userinfo-responsestrategy-fromtokenendpoint.png 680w"],
          "sizes": "(max-width: 680px) 100vw, 680px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      