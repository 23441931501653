import Layout from "../../../../src/layouts/mdx";
import JwtPayloadViewer from "../../../../src/components/JwtPayloadViewer";
import SwedishBankIdJwtSnippet from "../../../../src/snippets/jwt-payloads/swedish-bankid";
import template from "../../../../src/pages/verify/e-ids/files/swedbank-verksamhetsinfo-bankid-template.pdf";
import * as React from 'react';
export default {
  Layout,
  JwtPayloadViewer,
  SwedishBankIdJwtSnippet,
  template,
  React
};