import Layout from "../../../../src/layouts/mdx";
import ConsentRevocation from "../../../../src/components/ConsentRevocation";
import NorwegianBankIdJwtSnippet from "../../../../src/snippets/jwt-payloads/norwegian-bankid";
import NorwegianBankIdBiometricJwtSnippet from "../../../../src/snippets/jwt-payloads/norwegian-bankid-biometric";
import * as React from 'react';
export default {
  Layout,
  ConsentRevocation,
  NorwegianBankIdJwtSnippet,
  NorwegianBankIdBiometricJwtSnippet,
  React
};