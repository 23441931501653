import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "dkmitid",
      "nameidentifier": "159d89fca2db4300a52ab7865f7b1ff3",
      "sub": "{159d89fc-a2db-4300-a52a-b7865f7b1ff3}",
      "uuid": "3c6d9757-1e70-438a-8dd3-5f84398c2e25",
      "cvrNumberIdentifier": "12345678",
      "2.5.4.10": "Testorganisation nr. 12345678",
      "name": "Firstname Lastname",
      "employee": "true",
      "country": "DK",
      "productionUnit": "1092738120",
      "ridNumberIdentifier": "8636770830",
      "seNumber": "98202298"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      