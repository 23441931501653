import Layout from "../../../../src/layouts/mdx";
import FtnJwtSnippet from "../../../../src/snippets/jwt-payloads/finnish-trust-network";
import eca from "../../../../src/pages/verify/e-ids/files/telia-criipto_end_customer_agreement_12.7.2019.pdf";
import serviceDesc from "../../../../src/pages/verify/e-ids/files/Telia-Tunnistus palvelukuvaus-20190529-en.pdf";
import terms from "../../../../src/pages/verify/e-ids/files/Telia-Tunnistus-Yleiset-ehdot-yrityksille_31.5.2019-en_gb.pdf";
import * as React from 'react';
export default {
  Layout,
  FtnJwtSnippet,
  eca,
  serviceDesc,
  terms,
  React
};