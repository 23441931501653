import Layout from "../../../../src/layouts/mdx";
import { ImageContainer } from "../../../../src/components/MdxProvider";
import DanishMitIDCompanySignatoryJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid-erhverv-company-signatory";
import DanishMitIDErhvervJwtSnippet from "../../../../src/snippets/jwt-payloads/danish-mitid-erhverv";
import * as React from 'react';
export default {
  Layout,
  ImageContainer,
  DanishMitIDCompanySignatoryJwtSnippet,
  DanishMitIDErhvervJwtSnippet,
  React
};