import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Highlight icon="info" mdxType="Highlight">
      <p>{`If using custom fonts or images, remember to add them to the style origins of your tenant to avoid potential Content Security Policy violations.`}<br parentName="p"></br>{`
`}{`From the `}<a parentName="p" {...{
          "href": "https://dashboard.criipto.com/styling"
        }}>{`dashboard`}</a>{`, go to `}<inlineCode parentName="p">{`Styling`}</inlineCode>{` -> `}<inlineCode parentName="p">{`General`}</inlineCode>{`, and add the host names as Style origins:`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "768px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.6875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA7UlEQVQY05WO23KEIBBE/f8vTB7clVVclIEBAddb0ilw40PeQtWp7um5FJU2BmQt1KgRo8fXPmPfUuHYZhylnrGvZ5398ebXr0vE8gqFSnRPfNQCn/UDtZC4PyTubY+m7XETHeqmRdNKSDXi9u6XXHRlLmdh4vKBfLjy0wSyDpY9iD20ddCGiyfrMRLDsAP7UHo5Hw2fM/bcSSkgBIcQPCp8H9jW5WRbC/99x7FDyCcGTaiYCUQDjBlPpQF6VJeSzgxgSyXLvsxqde1klOrhmFANqkMvBbJm5uQRAyNFd2lmTu7yf5mjwyt5LK8JP9bmyWKkoq7uAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Style origins",
            "title": "Style origins",
            "src": "/static/94f27b1d3a87377954fa6c5980734afd/e5715/style-origins.png",
            "srcSet": ["/static/94f27b1d3a87377954fa6c5980734afd/8514f/style-origins.png 192w", "/static/94f27b1d3a87377954fa6c5980734afd/804b2/style-origins.png 384w", "/static/94f27b1d3a87377954fa6c5980734afd/e5715/style-origins.png 768w", "/static/94f27b1d3a87377954fa6c5980734afd/12bff/style-origins.png 1032w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </Highlight>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      