import Layout from "../../../../src/layouts/mdx";
import previews from "../../../../src/pages/verify/guides/images/preview-sebankid.png";
import customStyling from "../../../../src/pages/verify/guides/images/custom-styling.png";
import StyleOriginsSnippet from "../../../../src/snippets/style-origins.mdx";
import * as React from 'react';
export default {
  Layout,
  previews,
  customStyling,
  StyleOriginsSnippet,
  React
};